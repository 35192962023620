import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {IntlProvider} from 'react-intl';

import esMsgs from "./langs/es.json";
import enMsgs from "./langs/en.json";

import App from './App';
import * as serviceWorker from './serviceWorker';
import {HashRouter} from "react-router-dom";

const messages: any = {
    es: esMsgs,
    en: enMsgs
};
const language = (navigator.languages && navigator.languages[0]) || navigator.language || 'es'; // Default is Spanish
const languageNoRegion = language.split(/[-_]/)[0];
const msgs = messages[languageNoRegion] || messages[language];

ReactDOM.render(
    <IntlProvider locale={language} messages={msgs}>
        <HashRouter>
            <App/>
        </HashRouter>
    </IntlProvider>,
    document.getElementById('root')
);
serviceWorker.register();
