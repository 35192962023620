import React, { Component } from "react";
import {WrappedComponentProps, injectIntl} from "react-intl";
import {NavLink} from "react-router-dom";
import Typing from "react-typing-animation";
import KeyHandler, {KEYPRESS} from "react-key-handler";

class Menu extends Component<WrappedComponentProps & {menuStatus: any, doMenu: any}, {}>
{
    private items: Array<string>;

    constructor(props: WrappedComponentProps & {menuStatus: any, doMenu: any}) {
        super(props);

        this.items = [
            'profiles', 'why', 'about', 'projects', 'contact'
        ];
        console.log('Menu created');
    }

    componentWillUnmount() {
        console.log('Menu will unmounted');
        this.props.doMenu();
    }

    render() {
        let loading = this.props.intl.formatMessage({ id: 'menu.loading' });
        let menuItems = this.items.map((item, index) => {
            const link = '/' + item;
            const text = this.props.intl.formatMessage({ id: 'menuItem.' + item});

            return <li key={'item-' + index}><NavLink to={link}
                                                      id={'item' + (++index)}
                                                      key={'nav-' + index}>{text}</NavLink>
                   </li>
        });
        let keys = this.items.map((item, index) => {
            let k = index + 1;
            return <KeyHandler key={'kh-' + index}
                               keyEventName={KEYPRESS}
                               keyValue={k.toString()}
                               onKeyHandle={this.goToItem}
                   />
        });

        let menu = (
            <Typing speed={1} cursorClassName="cursor">
                <p>.....................</p>
                <p>.......... {loading}</p>
                <ol>
                    {menuItems}
                </ol>
            </Typing>
        );
        if (this.props.menuStatus()) {
            menu = (
                <div>
                    <p>.....................</p>
                    <p>.......... {loading}</p>
                    <ol>
                        {menuItems}
                    </ol>
                </div>
            );
        }

        return (
            <div className="">
                {keys}
                {menu}
            </div>
        );
    }


    private goToItem(event: KeyboardEvent) {
        event.preventDefault();

        let e = document.getElementById('item' + event.key);
        e && e.click();
    }
}

export default injectIntl(Menu);
