import React, { Component } from "react";
import {FormattedMessage, WrappedComponentProps, injectIntl} from "react-intl";
import {NavLink} from "react-router-dom";
import KeyHandler, {KEYPRESS} from "react-key-handler";
import Markdown from "react-markdown";

class Page extends Component<WrappedComponentProps & {reset: any, resetMenu: any}, {}>
{
    constructor(props: WrappedComponentProps & {reset: any, resetMenu: any}) {
        super(props);

        this.reset = this.reset.bind(this);
        this.goToHome = this.goToHome.bind(this);
        this.goToMenu = this.goToMenu.bind(this);
    }

    reset() {
        this.props.reset();
        this.props.resetMenu();
    }

    render() {
        let hash = window.location.hash.substr(1).replace('/', '');
        let title = this.props.intl.formatMessage({ id: 'menuItem.' + hash });
        let text = this.props.intl.formatMessage({ id: 'features.' + hash });

        return (
            <div className="page">
                <p className="title">{title}</p>
                <Markdown source={text}></Markdown>
                <KeyHandler
                    keyEventName={KEYPRESS}
                    keyValue="m"
                    onKeyHandle={this.goToMenu}
                />
                <nav className="back-actions">
                    <p>----------&gt;&nbsp;
                        <NavLink to="/menu" id="back"><FormattedMessage id="menu.back" /> (M)</NavLink></p>
                </nav>
            </div>
        );
    }


    private goToHome(event: any) {
        event.preventDefault();

        let e = document.getElementById('home');
        e && e.click();
    }

    private goToMenu(event: any) {
        event.preventDefault();

        let e = document.getElementById('back');
        e && e.click();
    }
}

export default injectIntl(Page);
