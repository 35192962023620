import React, {Component} from 'react';
import {Route, NavLink, Switch} from "react-router-dom";
import {injectIntl, WrappedComponentProps, FormattedMessage} from 'react-intl';
import Typing from 'react-typing-animation';
import KeyHandler, { KEYPRESS } from 'react-key-handler';

import logo1 from './assets/mood_logo1.svg';
import logo2 from './assets/mood_logo2.svg';
import './App.scss';
import Menu from "./components/Menu";
import Page from "./components/Page";


type AppState = { isInside: boolean, isMenu: boolean, isMenuTyped: boolean };

class App extends Component<WrappedComponentProps, AppState>
{
    constructor(props: WrappedComponentProps) {
        super(props);

        let urlHash = window.location.hash;
        let isHome = /#\/$/.test(urlHash);
        console.log('location', urlHash);
        this.state = { isInside: true, isMenu: !isHome, isMenuTyped: false };

        this.start = this.start.bind(this);
        this.reset = this.reset.bind(this);
        this.startMenu = this.startMenu.bind(this);
        this.resetMenu = this.resetMenu.bind(this);
        this.typedMenu = this.typedMenu.bind(this);
        this.isMenuDone = this.isMenuDone.bind(this);

        this.openMenu = this.openMenu.bind(this);
    }

    componentDidMount() {
        console.log('App mounted');
    }

    start() {
        this.setState({isInside: true});
    }

    reset() {
        this.setState({isMenu: false, isMenuTyped: false});
    }

    startMenu() {
        this.setState({isMenu: true});
    }

    resetMenu() {
        this.setState({isMenu: false});
    }

    typedMenu() {
        this.setState({isMenuTyped: true});
    }

    isMenuDone(): boolean {
        return this.state.isMenuTyped;
    }

    removeStart() {
        const elem = document.querySelector('.start');
        if (elem) {
            elem.className = 'hidden'
        }
    }

    render(): JSX.Element {
        let nowRaw =  this.props.intl.formatTime(new Date(), {hour12: true});
        const now = nowRaw.replace(/[\.\s]/gm, '');
        const prompt = this.props.intl.formatMessage({ id: 'menu.prompt' });
        const menuStart = this.props.intl.formatMessage({ id: 'menu.start' });

        let intro, entryText, keyReset, keyMenu, logo;
        let mainClasses = 'mo-main';

        intro = (<>
            <p>.....................</p>
            <p>mood481@{now}:~</p>
            <p className="prompt">{prompt} P&gt;</p>
            <p className="start">
                {menuStart}
                <span className={this.state.isMenu ? 'hidden' : 'blink'}> (
                    <NavLink id="startPoint"
                             to="/menu"
                             onClick={this.startMenu}><FormattedMessage id="general.yes"/></NavLink>/
                    <a onClick={this.removeStart}><FormattedMessage id="general.no"/></a>
                    )
                </span>
            </p>
        </>)
        if (this.state.isMenu) {
            logo = logo2;
            mainClasses += ' mo-mood';
        } else {
            keyReset = <KeyHandler keyEventName={KEYPRESS} keyValue="n" onKeyHandle={this.reset} />;
            keyMenu = <KeyHandler keyEventName={KEYPRESS} keyValue="s" onKeyHandle={this.openMenu} />;
            logo = logo1;
            intro = (<>
                <Typing speed={1} cursorClassName="cursor">
                    {intro}
                </Typing>
            </>);
        }

        entryText = (
            <section className="mo-menu mo-content">
                <Switch>
                    <Route path="/menu"
                           render={(props) => <Menu menuStatus={this.isMenuDone} doMenu={this.typedMenu} />}/>
                    <Route path="/:page"
                           render={(props) => <Page reset={this.reset} resetMenu={this.resetMenu} />}/>
                </Switch>
            </section>
        );

        console.log('status', this.state);

        return (
            <div className={mainClasses}>
                <div className="mo-wrapper">
                <div className="mo-container">
                    <header className="mo-header">
                        <img src={logo} className="mo-logo" alt="mood481_"/>
                    </header>
                    <section className="mo-intro mo-content">
                        {intro}
                    </section>
                    {keyReset}
                    {keyMenu}
                    {entryText}
                </div>
                </div>
            </div>
        );
    }

    private openMenu(event: any) {
        event.preventDefault();

        let e = document.getElementById('startPoint');
        e && e.click();
    }
}

export default injectIntl(App);
